<template>
    <BaseSection
        v-if="block"
        section-width="content"
    >
        <div class="card-block">
            <img
                v-if="block.image && block.image.length"
                :src="block.image[0].url"
                alt=""
                class="card-block__img"
            >
            <div
                v-if="block.title || block.description"
                class="card-block__content"
            >
                <h4
                    v-if="block.title"
                    class="card-block__title"
                >
                    {{ block.title }}
                </h4>
                <!-- eslint-disable vue/no-v-html -->
                <div
                    v-if="block.description"
                    class="card-block__description"
                    v-html="block.description"
                />
            </div>
            <!-- eslint-enable vue/no-v-html -->
            <BaseInput
                v-if="block.blockActionType === 'inputField'"
                :model-value="inputValue"
                :placeholder="block.caption ?? 'Enter your message'"
                class="card-block__action card-block__action--input"
                @update:model-value="inputValue = $event.target.value"
                @keydown.enter="onEnter"
            >
                <template #after>
                    <BaseButton
                        type="button"
                        class="button--round button--small"
                        @click.prevent="navigateToChat"
                    >
                        <BaseIcon icon="arrow-right" />
                    </BaseButton>
                </template>
            </BaseInput>

            <a
                v-else-if="block.blockActionType === 'button' && externalLink"
                :href="externalLink"
                :download="externalLink?.includes('.pdf') ? 'true' : undefined"
                target="_blank"
                class="dn-button base-button card-block__action card-block__action--button"
                @click="handleExternalLinkClick"
            >
                {{ block.action }}
            </a>

            <CraftLink
                v-else-if="block.blockActionType === 'button' && internalLink?.uri"
                :link="internalLink"
                class="dn-button base-button card-block__action card-block__action--button"
                @click="handleInternalLinkClick"
            >
                {{ block.action }}
            </CraftLink>
            <p
                v-if="block.caption"
                class="card-block__caption"
            >
                {{ block.caption }}
            </p>
        </div>
    </BaseSection>
</template>

<script setup lang="ts">
type Props = {
    block: {
        id?: string;
        image?: {
            url: string;
        }[];
        title?: string;
        description?: string;
        action?: string;
        blockActionType?: string;
        caption?: string;
        internalLink?: {
            uri: string;
        };
        externalLink?: string;
    }[]
}

const props = defineProps<Props>();
const path = useRoute().path;

const block = computed(() => Array.isArray(props.block) ?
    props.block?.[0] :
    props.block
);

const externalLink = computed(() => block.value?.externalLink);
const internalLink = computed(() => block.value?.internalLink?.[0]);

const inputValue = ref('');
const router = useRouter();

const { trackEvent } = useCustomGtmEvent();
const pageType = inject('pageType')?.pageType;

const navigateToChat = () => {
    trackEvent({
        event: 'start_chat',
        'page_type': pageType?.value ?? null
    });

    router.push(
        `/chat/${block.value?.id}${inputValue.value ? `?input=${inputValue.value.replaceAll(' ', '%20')}` : ''}`
    );
};

const { moduleProgress, setOriginPath } = useProgressStorage();

const route = useRoute();
const progress = ref(0);

if (route?.params?.module) {
    progress.value = moduleProgress(route.params.module);
}

const handleExternalLinkClick = () => {
    if (externalLink?.includes('.pdf')) {
        trackEvent({
            event: 'download_pdf_module',
            'page_type': pageType?.value ?? null,
            'file_name': externalLink ?? null
        });

        // store
    }

    trackEvent({
        event: 'button_click',
        'page_type': pageType?.value ?? null,
        'click_text': block.value?.action ?? null
    });
};
const handleInternalLinkClick = () => {
    if (
        internalLink.value?.uri &&
        internalLink.value?.uri.includes('module/')
    ) {
        setOriginPath(path);

        trackEvent({
            event: 'module_entry',
            'page_type': pageType?.value ?? null,
        });
    }

    trackEvent({
        event: 'button_click',
        'page_type': pageType?.value ?? null,
        'click_text': block.value?.action ?? null
    });
};

const onEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
        navigateToChat();
    }
};
</script>

<style lang="less">
.card-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.44rem 1.5rem;
    position: relative;
    gap: 1.12rem;

    width: 100%;
    border-radius: 6px;

    & > * {
        text-align: center;
        z-index: 1;
    }

    p {
        width: 100%;
    }

    .dn-input__field-body {
        padding-left: 1.25rem;
    }

    .dn-input {
        width: 100%;
        border-radius: var(--borderRadius-xl);

        border: none;
        box-shadow: 0 0 4px 0 #00000017;

        ::placeholder {
            color: var(--color-primary-700);
            font-size: 1.125rem;
        }
    }

    .dn-input__after {
        width: auto;
        margin-right: .5625rem;

        .base-button {
            &.button--round {
                border-radius: 50%;
                padding: 0.2rem;
            }

            .dn-icon {
                margin-left: 0;
                font-size: 1.1em;
            }

            .button--small {
                font-size: 1rem;
                max-height: 32px;
                max-width: 32px;
            }

            &:hover {
                background: var(--color-primary-700);
            }
        }
    }

    &:before {
        background: var(--color-alt-light, var(--color-primary-light));
        content: '';
        inset: 0;
        position: absolute;
        border-radius: var(--borderRadius-xs);
        z-index: 0;
        opacity: 1;
    }
}

.card-block__action--button {
    width: 100%;
    justify-content: center;
}

.card-block__title {
    margin: 0;
}

.card-block__img {
    max-height: 8rem;

    @media @q-md-min {
        max-height: 16rem;
    }
}

@media @q-lg-min {
    .card-block p,
    .card-block__description {
        max-width: 37.5rem;
        margin: 0 auto;
    }
}

.card-block__caption {
    margin-top: 0;
    font-size: 1rem;
    opacity: .4;
}
</style>
